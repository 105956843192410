<template>
    <b-container>
        <b-row>
            <b-col sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <div class="text-center">
                            <h5 class="card-title"><i class="ri-edit-line"></i> {{ $t('globalTrans.personal_information') }}</h5>
                        </div>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                            <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                                <b-row>
                                    <b-col sm="4">
                                        <ValidationProvider name="Name" vid='name' rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="name"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                    {{ $t('user.name')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="name"
                                                v-model="profileInfo.name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Name (bn)" vid='name_bn' rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="name_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('user.name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="name_bn"
                                                v-model="profileInfo.name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Father name" vid='father_name' rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="father_name"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('externalUserIrrigation.father_name')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="father_name"
                                                v-model="profileInfo.father_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Father name (bn)" vid='father_name_bn' rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                :label="$t('externalUserIrrigation.father_name_bn')"
                                                label-for="father_name_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('externalUserIrrigation.father_name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="father_name_bn"
                                                v-model="profileInfo.father_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="NID" vid='nid' rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="nid"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('externalUserIrrigation.nid')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                type="number"
                                                id="nid"
                                                v-model="profileInfo.nid"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Mobile" vid='mobile_no' rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="mobile_no"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('user.phone_no')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                type="number"
                                                id="mobile_no"
                                                v-model="profileInfo.mobile_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="District" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="district_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="profileInfo.district_id"
                                                :options="districtList"
                                                id="district_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Upazila" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="far_upazila_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="profileInfo.upazilla_id"
                                                :options="upazilaList"
                                                id="far_upazila_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Organization" vid="region_id" rules="">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="org_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('externalUserIrrigation.organization')}}
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="profileInfo.org_id"
                                                :options="orgList"
                                                id="org_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Region" vid="region_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="region_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('externalUserIrrigation.region')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="profileInfo.region_id"
                                                :options="regionList"
                                                id="region_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Zone" vid="zone_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="zone_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('ginnerGrower.zone')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="profileInfo.zone_id"
                                                :options="zoneList"
                                                id="zone_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Unit" vid="unit_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="unit_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('ginnerGrower.unit')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="profileInfo.unit_id"
                                                :options="unitList"
                                                id="unit_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Address (En)" vid='address' rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="address"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('ginnerGrower.address_en')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="address"
                                                v-model="profileInfo.address"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Address (Bn)" vid='address_bn'>
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="address"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('ginnerGrower.address_bn')}}
                                            </template>
                                                <b-form-input
                                                id="address"
                                                v-model="profileInfo.address_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Land Area" vid='land_area' rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="land_area"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('externalUserIrrigation.land_area')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="land_area"
                                                v-model="profileInfo.land_area"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <ValidationProvider name="Gender" vid='gender' rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="gender"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.gender')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-select
                                                plain
                                                id="gender"
                                                :options="genderList"
                                                v-model="profileInfo.gender"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                  <template v-slot:first>
                                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                  </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <div class="my-3 text-right">
                                    <b-button-group>
                                        <b-button type="submit" variant="primary"
                                            @click="profileInfo.is_draft = 1"
                                            class="mr-1 rounded"
                                            title="Draft copy will be saved, you can edit this anytime.">
                                            <i class="far fa-save ml-1"></i>
                                            {{ $t('externalUserIrrigation.save_draft') }}
                                        </b-button>
                                        <b-button type="submit"
                                            @click="profileInfo.is_draft = 2"
                                            variant="danger"
                                            title="Final Save cannot be reverted!"
                                            class="ml-1 rounded">
                                            <i class="fas fa-lock ml-1"></i>
                                            {{ $t('externalUserIrrigation.final_save') }}
                                        </b-button>
                                    </b-button-group>
                                </div>
                            </b-form>
                            </ValidationObserver>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { ginnerGrowerProfileUpdate } from '../../../api/routes'
import { Common } from '@/mixins/helper-functions'

export default {
    mixin: [Common],
    created () {
        this.profileInfo = this.$store.state.ExternalUserIrrigation.ginnerGrowerDetails
      this.profileInfo.org_id = 10
    },
    data () {
        return {
            profileInfo: {
                name: '',
                name_bn: '',
                father_name: '',
                father_name_bn: '',
                nid: '',
                mobile_no: '',
                division_id: '',
                district_id: '',
                upazilla_id: '',
                org_id: 10,
                region_id: '',
                unit_id: '',
                zone_id: '',
                address: '',
                address_bn: '',
                land_area: '',
                is_draft: 1,
                gender: 0
            },
            upazilaList: [],
            // regionList: [],
            zoneList: [],
            unitList: [],
            id: '',
            loading: false
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        orgList () {
            return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
        },
        districtList () {
            return this.$store.state.ExternalUserIrrigation.commonObj.districtList
        },
        regionList () {
          return this.$store.state.ExternalUserIrrigation.agriMarketing.regionList
        },
        genderList () {
          return this.$store.state.ExternalUserIrrigation.genderList.map(item => {
            const customItem = {
              text: this.currentLocale === 'en' ? item.text_en : item.text_bn
            }
            return Object.assign({}, item, customItem)
          })
        }
    },
    watch: {
        // 'profileInfo.org_id': function (newVal, oldVal) {
        //     if (newVal !== oldVal) {
        //         this.regionList = this.getRegionList(newVal)
        //     }
        // },
        'profileInfo.region_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.zoneList = this.getZoneList(newVal)
            }
        },
        'profileInfo.zone_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.unitList = this.getUnitList(newVal)
            }
        },
        'profileInfo.district_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.upazilaList = this.getUpazilaList(newVal)
            }
        }
    },
    methods: {
        async register () {
            let result = null
            const loadingState = { loading: true, listReload: false }
            this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', loadingState)
            result = await RestApi.putData(agriMarketingServiceBaseUrl, `${ginnerGrowerProfileUpdate}` + '/' + this.profileInfo.id, this.profileInfo)
            if (result.success) {
                // const result1 = await RestApi.putData(authServiceBaseUrl, `${ginnerGrowerProfileUserUpdate}` + '/' + this.profileInfo.mobile_no)
                // if (result1.success) {
                //     this.$toast.success({
                //         title: 'Success',
                //         message: 'Data updated successfully',
                //         color: '#D6E09B'
                //     })
                //     this.$router.push('/grower/profile')
                // } else {
                //     this.$toast.error({
                //         title: 'Error',
                //         message: 'Operation failed! Please, try again.'
                //     })
                // }
              this.$router.push('/grower/profile')
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
            }
            this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })

            if (result.success === true) {
                this.getFarmer()
                setTimeout(async () => {
                    await this.$router.push('/warehouse-farmer/profile')
                }, 1000)
            }
        },
        getRegionList (orgId = null) {
            const regionList = this.$store.state.ExternalUserIrrigation.agriMarketing.regionList
            if (regionList) {
                return regionList.filter(item => item.org_id === orgId && item.status === 1)
            }
            return regionList
        },
        getZoneList (regionId = null) {
            const zoneList = this.$store.state.ExternalUserIrrigation.agriMarketing.zoneList
            if (zoneList) {
                return zoneList.filter(item => item.region_id === regionId && item.status === 1)
            }
            return zoneList
        },
        getUnitList (zoneId = null) {
            const unitList = this.$store.state.ExternalUserIrrigation.agriMarketing.unitList
            if (unitList) {
                return unitList.filter(item => item.zone_id === zoneId && item.status === 1)
            }
            return unitList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        }
    }
}
</script>
